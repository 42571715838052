import { useMemo } from 'react';
import { useRouter } from 'next/router';

const getSearchParams = () => {
  if (typeof window === 'undefined') {
    return new URLSearchParams();
  }

  return new URLSearchParams(window.location.search);
};

const getValue = (key: string) => {
  return getSearchParams().get(key);
};

export default function useQueryParam(key: string, defaultValue?: string) {
  const { query, replace } = useRouter();

  const updateQueryParam = (newValue: string) => {
    const urlSearchParam = getSearchParams();

    if (newValue) {
      urlSearchParam.set(key, newValue);
    } else {
      urlSearchParam.delete(key);
    }

    replace({ query: urlSearchParam.toString() }, undefined, { shallow: true });
  };

  const queryParam = useMemo(() => {
    if (query[key]) {
      return query[key];
    }

    return getValue(key);
  }, [key, query]);

  return [queryParam ?? defaultValue, updateQueryParam] as const;
}
