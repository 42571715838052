import fs from 'fs';
import path from 'path';
import {
  fetchApiWithRetries,
  checkIsDevelopment,
  shouldFetchPost,
  getWpLocaleEnum,
} from './_helpers';
import { localizeMeta } from './_wordpress';
import { BLOG_LOCALES } from '../locales';

export const isLocalizedBlog = (locale) => {
  return BLOG_LOCALES.includes(locale);
};

export const getFeaturedPostQuery = () =>
  BLOG_LOCALES.reduce((accum, locale) => {
    return (accum += `featuredPost_${locale.replace(
      '-',
      ''
    )}: posts(where: {includedInHomePage: "true", tagSlugIn: "${locale}"}, first: 1) {
    nodes {
      title
      slug
      meta
    }
  }\n`);
  }, '');

const globalOptions = (construct, slug, subPath) => {
  const wpSEO = construct?.meta?.seo || {};

  return {
    careers: () => {
      return {
        seo: {
          title: 'Rippling Careers & Jobs | Open Roles',
          description:
            "We're a team of smart people that likes solving hard problems. Come join us!",
          _isIndexed: false,
          notFound: true,
        },
        header: {
          button: ['demo', 'login'],
          nav: [true],
          inverted: [false],
          unstick: [false],
        },
      };
    },
    career: () => {
      // @TODO Deprecate w/ proper 404
      if (!construct.meta.career.content) {
        return {
          seo: {
            title: 'Oops—this job is missing',
            _isIndexed: false,
            notFound: true,
          },
          header: {
            button: ['demo', 'login'],
            nav: [false],
            inverted: [false],
          },
        };
      } else {
        const { title } = construct.wp;
        const description =
          'Rippling is the first way for businesses to manage all of their HR & IT — payroll, benefits, computers, apps, and more';

        return {
          seo: {
            uri: `/careers/${slug}`,
            title,
            description,
            _isIndexed: false,
          },
          header: {
            button: ['demo', 'login'],
            nav: [false],
            inverted: [false],
          },
        };
      }
    },
    customerSpotlight: () => {
      const colorTheme = construct?.meta?.customers?._colorTheme
        ? construct?.meta?.customers?._colorTheme
        : '';
      return {
        seo: {
          _isIndexed: true,
          uri: `/customers/${slug}`,
          ...wpSEO,
        },
        header: {
          button: ['login', 'demo'],
          unstick: [false],
          theme: `${colorTheme}`,
        },
      };
    },
    page: () => {
      const wpHeader = construct.meta?.page?._headerOptions || {};
      const wpFooter = construct.meta?.page?._footerOptions || null;

      const pageSlug = slug === 'home' ? '' : `/${slug}`;

      const seo = {
        ...wpSEO,
        uri: `${subPath || ''}${pageSlug}`,
      };

      return {
        seo: seo,
        header: wpHeader,
        footer: {
          options: wpFooter,
        },
      };
    },
    partial: () => {
      const wpHeader = construct.meta?.page?._headerOptions || {};
      const wpFooter = construct.meta?.page?._footerOptions || null;

      const isCountryGuidesIndex = slug === 'country-hiring-index';
      const isCustomersIndex = slug === 'customers-meta';
      const isRecipesIndex = slug === 'recipes-meta';

      const seo = {
        ...wpSEO,
        ...(isCountryGuidesIndex ? { uri: '/country-hiring' } : {}),
        ...(isCustomersIndex ? { uri: '/customers' } : {}),
        ...(isRecipesIndex ? { uri: '/recipes' } : {}),
      };

      return {
        seo: seo,
        header: wpHeader,
        footer: {
          options: wpFooter,
        },
      };
    },
    posts: () => ({
      seo: {
        uri: `/blog${subPath || ''}${slug ? `/${slug}` : ''}`,
        image: 'https://rippling2.imgix.net/blog.jpg',
        description:
          'The Official Rippling Blog: The best source of information for employee management tips, guidance, news and more across HR, IT, Finance, and Operations.',
        _isIndexed: true,
        title: 'Blog | Rippling',
      },
      header: {
        button: ['demo'],
        isBlog: true,
      },
    }),
    post: () => {
      return {
        seo: {
          uri: `/blog${slug ? `/${slug}` : ''}`,
          ...wpSEO,
          _isIndexed: !wpSEO._isUnIndexed,
        },
        header: {
          button: ['demo'],
          isBlog: true,
        },
      };
    },
    events: () => {
      return {
        seo: {
          uri: '/events',
          description:
            'Upcoming events where you can connect with Rippling.',
          _isIndexed: true,
          title: 'Rippling Events',
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    resourcesTemp: () => {
      return {
        seo: {
          uri: '/resources',
          description:
            'Resources to help your HR, IT, and Finance administration efforts',
          _isIndexed: true,
          title: 'Resources | Rippling',
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    resourceTemp: () => ({
      seo: {
        uri: `/resources/${slug}`,
        ...wpSEO,
      },
      header: {
        nav: [true],
        button: ['demo'],
      },
    }),
    webinars: () => {
      return {
        seo: {
          uri: '/webinars',
          description:
            'Upcoming and recorded webinars to help your HR and IT administration efforts',
          _isIndexed: true,
          title: 'Rippling Webinars',
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    'customer-events': () => {
      return {
        seo: {
          uri: '/events',
          description:
            'Upcoming and recorded events to help you make the most of the Rippling platform',
          _isIndexed: true,
          title:
            'Rippling Customer Events | Learn how to get the most out of Rippling',
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    'customer-webinars': () => {
      return {
        seo: {
          uri: '/customer-webinars',
          description:
            'Upcoming and recorded webinars to help you make the most of the Rippling platform',
          _isIndexed: true,
          title:
            'Rippling Customer Webinars | Learn how to get the most out of Rippling',
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    event: () => ({
      seo: {
        uri: `/events/${slug}`,
        ...wpSEO,
      },
      header: {
        nav: [false],
        button: ['demo'],
      },
    }),
    webinar: () => ({
      seo: {
        uri: `/webinars/${slug}`,
        ...wpSEO,
      },
      header: {
        nav: [false],
        button: ['demo'],
      },
    }),
    meetup: () => ({
      seo: {
        _isIndexed: false,
        uri: `/meetups/${slug}`,
        ...wpSEO,
      },
      header: {
        nav: [false],
        button: ['demo'],
      },
    }),
    recipe: () => ({
      seo: {
        featuredImage: null,
        uri: `/recipes/${slug}`,
        description: construct.meta?.recipes.summaryBoxDescription,
        _isIndexed: true,
        title: `${construct.meta?.recipes.summaryBoxTitle} | Recipes | Rippling`,
      },
      header: {
        nav: [true],
        button: ['login', 'demo'],
        inverted: [false],
      },
    }),
    glossaryTerm: () => {
      return {
        seo: {
          ...wpSEO,
          uri: `/glossary/${slug}`,
          _isIndexed: !wpSEO._isUnIndexed,
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    glossaryTerms: () => {
      return {
        seo: {
          ...wpSEO,
          uri: `/glossary`,
          _isIndexed: !wpSEO._isUnIndexed,
          title: 'Glossary: HR and Payroll Terms Explained | Rippling',
          description: 'Whether you\'re managing a small startup or a worldwide workforce, it\'s easy to get lost in the lingo. Become an expert with our glossary of HR, payroll, spend, and IT terms.'
        },
        header: {
          nav: [true],
          button: ['login', 'demo'],
        },
      };
    },
    countryGuide: () => {
      const isEmployeesPost =
        construct.meta.countryGuidePost?._countryGuideType !== 'contractors';
      return {
        seo: {
          ...wpSEO,
          uri: `/country-hiring/${slug}`,
          _isIndexed: !wpSEO._isUnIndexed,
        },
        header: {
          button: ['demo'],
          inverted: [isEmployeesPost],
          transparent: [isEmployeesPost],
        },
      };
    },
  };
};

export const setGlobalOptions = ({ data, type, slug, subPath }) => {
  const { construct, globals, mode } = data;
  const getGlobalData = globalOptions(construct, slug, subPath)[type];
  const { seo, header, footer } = getGlobalData
    ? getGlobalData()
    : { seo: {}, header: {}, footer: {} };
  const styles = {};

  return {
    head: {
      ...globals.head,
      seo: {
        ...seo,
      },
      styles,
      mode,
    },
    header: {
      ...globals.header,
      options: {
        ...header,
      },
    },
    footer: {
      ...globals.footer,
      ...(footer || null),
    },
  };
};

const filterHeaderData = (data, locale) => {
  data.products.productTypes.forEach((item) => {
    if (item.platform?.resourceHighlight?.stories) {
      item.platform.resourceHighlight.stories = item.platform.resourceHighlight.stories.filter(
        (story) => {
          const excludes = story._excludeLocales || [];
          return !excludes.includes(locale);
        }
      );

      if (item.platform.resourceHighlight.stories.length === 0) {
        delete item.platform.resourceHighlight;
      }
    }
  });

  if (data.platform?.resourceHighlight?.stories) {
    data.platform.resourceHighlight.stories = data.platform.resourceHighlight.stories.filter(
      (story) => {
        const excludes = story._excludeLocales || [];
        return !excludes.includes(locale);
      }
    );
    if (
      data.platform.resourceHighlight.stories.length === 0 &&
      data.platform.resourceHighlight._excludeLocales
    ) {
      delete data.platform.resourceHighlight;
    }
  }


  data.solutions.subProducts = data.solutions.subProducts.map((pt) => {
    if (pt.productsCol) {
      pt.productsCol.map((p) => {
        if (p.badgeList.badges) {
          p.badgeList.badges = p.badgeList.badges.filter(
            (badge) => !badge._excludeLocales?.includes(locale)
          );
          return p;
        }
      });
      return pt;
    }
  });

  data.global.subProducts = data.global.subProducts.map((pt) => {
    if (pt.productsCol) {
      pt.productsCol.map((p) => {
        if (p.badgeList.badges) {
          p.badgeList.badges = p.badgeList.badges.filter(
            (badge) => !badge._excludeLocales?.includes(locale)
          );
          return p;
        }
      });
      return pt;
    }
  });

  data.globalProducts.items = data.globalProducts.items.filter(
    (item) => !item._excludeLocales?.includes(locale)
  );

  data.globalResources.items = data.globalResources.items.filter(
    (item) => !item._excludeLocales?.includes(locale)
  );

  data.products.productTypes = data.products.productTypes.map((pt) => {
    // console.log(pt)
    if (pt.platform.subProducts) {
      pt.platform.subProducts.map((p) => {
        // console.log(p);
        if (p.productsCol) {
          // console.log(p.productsCol)
          p.productsCol.map((col) => {
            if (col.badgeList.badges) {
              col.badgeList.badges = col.badgeList.badges.filter(
                (badge) => !badge._excludeLocales?.includes(locale)
              );
            }
            return col;
          });
          return p;
        }
      });
    }
    // p.badgeList.badges = p.badgeList.badges.filter(
    //   (badge) => !badge._excludeLocales?.includes(locale)
    // );
    // pt?.solutions?.items
    //   ? (pt.solutions.items = pt.solutions.items.filter(
    //       (item) => !item._excludeLocales?.includes(locale)
    //     ))
    //   : null;
    return pt;
  });
  data.resources.resources.items = data.resources.resources.items.filter(
    (item) => !item._excludeLocales?.includes(locale)
  );
};

const filterFooterData = (data, locale) => {
  data.columns = data.columns.map((column) => {
    column.items = column.items.filter(
      (item) => !item._excludeLocales?.includes(locale)
    );
    return column;
  });
  data.columns = data.columns.filter(
    (column) => !column._excludeLocales?.includes(locale)
  );
};

const parseGlobals = (globals) => {
  const newGlobals = JSON.parse(JSON.stringify(globals));

  newGlobals.header.meta = JSON.parse(newGlobals.header.meta);

  Object.keys(newGlobals).map((key) => {
    if (/featuredPost/.test(key)) {
      newGlobals[key].nodes[0].meta = JSON.parse(newGlobals[key].nodes[0].meta);
    }
  });
  newGlobals.footer.meta = JSON.parse(newGlobals.footer.meta);
  newGlobals.topbars.meta = JSON.parse(newGlobals.topbars.meta);

  return newGlobals;
};

const localizeGlobals = (globals, locale) => {
  const newGlobals = JSON.parse(JSON.stringify(globals));

  newGlobals.header.meta = localizeMeta(newGlobals.header.meta, locale);

  Object.keys(newGlobals).map((key) => {
    if (/featuredPost/.test(key)) {
      newGlobals[key].nodes[0].meta = localizeMeta(
        newGlobals[key].nodes[0].meta,
        locale
      );
    }
  });
  newGlobals.footer.meta = localizeMeta(newGlobals.footer.meta, locale);
  newGlobals.topbars.meta = localizeMeta(newGlobals.topbars.meta, locale);

  return newGlobals;
};

const fetchGlobals = async ({ locale }) => {
  const pageFields = `
    data
    meta (locale: ${getWpLocaleEnum(locale)})
    modified
  `;

  const featuredPostQuery = getFeaturedPostQuery();

  // @TODO Fix context situation so morePosts can be moved down
  const globals = await fetchApiWithRetries(`
    query Globals {
      header: partial(id: "2024-header", idType: SLUG) {
        ${pageFields}
      }
      footer: partial(id: "footer-new", idType: SLUG) {
        ${pageFields}
      }
      topbars: partial(id: "topbars", idType: SLUG) {
        ${pageFields}
      }
      ${featuredPostQuery}
    }
  `);

  return localizeGlobals(parseGlobals(globals), locale);
};

const pullGlobalsFromPrebuildCache = (locale) => {
  try {
    const pulledGlobalsJSON = fs.readFileSync(
      path.resolve('./cache/globals/globals.json'),
      { encoding: 'utf-8' }
    );

    const pulledGlobals = JSON.parse(pulledGlobalsJSON);

    const globals = localizeGlobals(parseGlobals(pulledGlobals), locale);
    // console.log(globals);
    return globals;
  } catch (e) {
    // console.log('e: ', e)
    // console.log('Failed to pull globals from pre-build cache')
    return null;
  }
};

const retrieveGlobals = async ({ locale }) => {
  let globals;

  const runFetchGlobals = async () => await fetchGlobals({ locale });
  const runPullGlobals = () => pullGlobalsFromPrebuildCache(locale);

  if (shouldFetchPost()) {
    globals = await runFetchGlobals();
    if (!globals) {
      globals = runPullGlobals();
    }
  } else {
    globals = runPullGlobals();
    if (!globals) {
      globals = await runFetchGlobals();
    }
  }

  return globals;
};

export const queryGlobalData = async ({ locale, previewData }) => {
  const globals = await retrieveGlobals({ locale });

  const headerData = globals.header.meta?.data?.data;
  filterHeaderData(headerData, locale);

  Object.keys(globals).map((key) => {
    if (/featuredPost/.test(key)) {
      const featuredBlogPost = globals[key].nodes[0];

      headerData.resources[key] = {
        ...headerData.resources.featuredBlogPost,
        title: featuredBlogPost.meta?.seo?.title || featuredBlogPost.title,
        slug: featuredBlogPost.slug,
        headerImage: featuredBlogPost.meta?.blogPostData?._heroImageSrc || '',
      };
    }
  });

  const footerData = globals.footer.meta?.data?.data;
  filterFooterData(footerData, locale);

  const topbarsData = globals.topbars.meta?.data?.data;

  return {
    header: {
      menu: headerData,
      topbars: topbarsData,
    },
    footer: {
      menu: footerData,
    },
  };
};

export const getGlobals = async ({
  data,
  type,
  slug,
  subPath,
  locale,
  previewData,
}) => {
  const hardRefresh = previewData?.hardRefresh;

  data.globals = await queryGlobalData({ locale });

  const { header, footer, head } = setGlobalOptions({
    data,
    type,
    slug,
    subPath,
  });

  const result = {
    ...data.globals,
    header,
    footer,
    head,
  };

  return result;
};
