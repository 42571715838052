import { LOCALES } from '../locales';
import {
  readFileWithLog,
  readOrFetchFromPublicWithLog,
  writeFileToPublicWithLog,
  writeFileWithLog,
} from './fs-helpers';
import { getCleanedSlug } from '../contentTypes/utils';

export const implementCaching = async ({
  fileName,
  fn,
  hardRefresh = false,
  logPrefix = 'cachingHelpers',
  writeToPublic = false,
} = {}) => {
  const filePath = `./cache/${fileName}`;

  let cachedJson = readFileWithLog({
    defaultValue: null,
    filePath,
    logPrefix,
  });

  if (!cachedJson || hardRefresh) {
    cachedJson = await fn();

    writeFileWithLog({
      content: JSON.stringify(cachedJson),
      filePath,
      logPrefix,
    });
  }

  if (writeToPublic) {
    writeFileToPublicWithLog({
      filePath: fileName,
      content: JSON.stringify(cachedJson),
      logPrefix,
    });
  }

  return cachedJson;
};

// Returns all the alternative pages
export const getAlternativePages = async ({
  contentType,
  locale: currentLocale,
  logPrefix = 'cachingHelpers',
  slug: currentSlug,
}) => {
  const slug = getCleanedSlug({
    slug: currentSlug,
  });

  const json = await readOrFetchFromPublicWithLog({
    defaultValue: [],
    filePath: 'all-pages.json',
    logPrefix,
    noSuccessLog: true,
  });

  const findLocale = (searchLocale = '') => {
    return json.find((groupedPages) => {
      return groupedPages.find(({ locale, path, postType }) => {
        let searchPath = '';

        if (searchLocale) {
          // Prepend locale to path
          searchPath = LOCALES.EN_US ? '' : `/${searchLocale}`;
        }

        searchPath = `${searchPath}/${slug}`;

        return (
          postType === contentType &&
          (!searchLocale || locale === searchLocale) &&
          path.endsWith(searchPath)
        );
      });
    });
  };

  // See if we can find match using current locale
  let foundPages = findLocale(currentLocale);

  if (!foundPages && currentLocale !== LOCALES.EN_US) {
    // Find using US locale
    foundPages = findLocale(LOCALES.EN_US);
  }

  if (!foundPages) {
    // Find a page that has matching path -- this is so we can set x-default
    foundPages = findLocale();
  }

  return (foundPages ?? []).map(({ locale, path }) => ({
    locale,
    path,
  }));
};
