import { LOCALES, LOCALES_LIST } from '../locales';
import {
  BLOG_POST_WP_TYPE,
  BLOG_POST_URI_PREFIX,
  getPublishedSlug,
} from './blogPost';
import {
  COUNTRY_GUIDE_WP_TYPE,
  COUNTRY_GUIDE_URI_PREFIX,
} from './countryGuide';
import {
  CUSTOMER_SPOTLIGHT_WP_TYPE,
  CUSTOMER_SPOTLIGHT_URI_PREFIX,
} from './customerSpotlight';
import {
  GLOSSARY_TERM_WP_TYPE,
  GLOSSARY_TERM_URI_PREFIX,
} from './glossaryTerm';
import { RECIPE_WP_TYPE, RECIPE_URI_PREFIX } from './recipe';

const indexableContentTypes = [
  BLOG_POST_WP_TYPE,
  RECIPE_WP_TYPE,
  COUNTRY_GUIDE_WP_TYPE,
  CUSTOMER_SPOTLIGHT_WP_TYPE,
  GLOSSARY_TERM_WP_TYPE,
];

// Since meta could be used in multiple places as string or object, creating a function to be safe
const getMeta = ({ node }) => {
  const { meta } = node;

  if (typeof meta === 'string') {
    try {
      return JSON.parse(meta);
    } catch (err) {
      return '';
    }
  }

  return meta;
};

export const getLocalesPublished = ({ node, contentType }) => {
  let locales = [];

  if (contentType === BLOG_POST_WP_TYPE) {
    // For blog posts, we only use tags
    locales = node?.tags?.nodes
      .map(({ name }) => name)
      .map((locale) => {
        if (/[A-Za-z]{2}-[A-Za-z]{2}/.test(locale)) {
          // For some reason, WP returns a lower-cased tag name
          const [lang, country] = locale.split('-');

          return `${lang}-${country.toUpperCase()}`;
        }

        return locale;
      });
  } else {
    const meta = getMeta({ node });
    if (!meta) {
      return [];
    }

    locales = meta.global?._localesPublished;
  }

  return (locales ?? [LOCALES.EN_US]).filter((locale) =>
    LOCALES_LIST.includes(locale)
  );
};

export const isIndexable = ({ node, contentType }) => {
  if (indexableContentTypes.includes(contentType)) {
    return true;
  }

  const meta = getMeta({ node });

  if (meta?.seo?._isIndexed?.[0] === true) {
    return true;
  }

  return false;
};

export const getUriPrefix = ({ contentType }) => {
  if (contentType === BLOG_POST_WP_TYPE) {
    return BLOG_POST_URI_PREFIX;
  }

  if (contentType === COUNTRY_GUIDE_WP_TYPE) {
    return COUNTRY_GUIDE_URI_PREFIX;
  }

  if (contentType === RECIPE_WP_TYPE) {
    return RECIPE_URI_PREFIX;
  }

  if (contentType === CUSTOMER_SPOTLIGHT_WP_TYPE) {
    return CUSTOMER_SPOTLIGHT_URI_PREFIX;
  }

  if (contentType === GLOSSARY_TERM_WP_TYPE) {
    return GLOSSARY_TERM_URI_PREFIX;
  }

  // if (type === 'resourcesTemp') {
  //   prefix = '/resources';
  // }

  return '';
};

export const getCleanedSlug = ({ slug }) => {
  if (slug === 'home') {
    return '';
  }

  if (slug === '404-2') {
    return '404';
  }

  return slug;
};
