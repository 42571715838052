export const PAGE_WP_TYPE = 'page';

export const getPagesQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query Pages($first: Int, $after: String, $status: [PostStatusEnum!]) {
      pages(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          parent {
            node {
              id
              slug
            }
          }
          children(where: {stati: $status}) {
            nodes {
              id
              slug
            }
          }
          slug
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};
