export const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseName = (name: string, emptyValue = '') => {
  const cleanedName = name.trim();
  const lastSpaceIdx = cleanedName.lastIndexOf(' ');

  if (lastSpaceIdx < 0) {
    return { firstName: cleanedName, lastName: emptyValue };
  }

  const firstName = cleanedName.substring(0, lastSpaceIdx);
  const lastName = cleanedName.substring(lastSpaceIdx + 1);

  return { firstName, lastName };
};
