import { createInstance } from '@optimizely/optimizely-sdk/dist/optimizely.lite.min.js';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import { SessionProvider } from 'next-auth/react';
import useTranscend from '../hooks/useTranscend';
import { get30Days } from '../helpers/date-helpers';
import ErrorBoundary from '../components/prescriptive/ErrorBoundary';
import { initDatadogRum } from '../lib/datadog';
import { checkIsDevelopment } from '../lib/_helpers';
import {
  getOptimizelyExperimentNames,
  getOptimizelyExperimentVariation,
  ExperimentDecisions,
} from '../lib/optimizely';

// initialize datadog
initDatadogRum();

const isDevelopment = checkIsDevelopment();

const reinitNavDropdowns = () => {
  const dropdowns = document.querySelectorAll('.dropdown');
  dropdowns.forEach((dropdown) => {
    dropdown.classList.add('-disabled');
    setTimeout(() => dropdown.classList.remove('-disabled'), 100);
  });
};

const setCookie = (cookies, key, value) => {
  cookies.set(key, value, {
    path: '/',
    domain: window.location.hostname,
    expires: get30Days(),
  });
};

const fetchDataFile = async () => {
  try {
    const response = await fetch(
      `${window.location.origin}/utility/experiments`
    );
    const text = await response.text();
    const json = text.match(/id="json">(.*?)</)[1];
    const datafile = JSON.parse(json);

    return datafile;
  } catch (err) {
    console.error('Error fetching Optimizely datafile', err);
    return null;
  }
};

const checkOptimizelyForDecision = async (cookies) => {
  try {
    const allCookies = cookies.getAll();
    const allCookieKeys = Object.keys(allCookies);

    for (let i = 0; i < allCookieKeys.length; i++) {
      const cookieKey = allCookieKeys[i];
      const featureFlagKey = cookieKey.match(/opt_(.*)_processing/)?.[1];

      if (featureFlagKey) {
        const experimentUserId = cookies.get(`opt_${featureFlagKey}_user`);
        const attributes = cookies.get(`opt_${featureFlagKey}_processing`);
        const _ga = cookies.get('_ga');

        if (attributes === 'false') {
          continue;
        }

        if (_ga) {
          attributes._ga = _ga;
        }

        cookies.set(`opt_${featureFlagKey}_processing`, false, { path: '/' });

        const datafile = await fetchDataFile();

        const optimizelyClient = createInstance({
          datafile: JSON.stringify(datafile),
          eventDispatcher: require('@optimizely/optimizely-sdk')
            .eventDispatcher,
        });

        const userContext = optimizelyClient.createUserContext(
          experimentUserId,
          attributes
        );

        const { flagKey, ruleKey, variationKey } = userContext.decide(
          featureFlagKey
        );

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: 'optimizely-decision',
          'optimizely-ruleKey': ruleKey,
          'optimizely-variationKey': variationKey,
          'optimizely-flagKey': flagKey,
          'optimizely-userId': experimentUserId,
        });
      }
    }
  } catch (e) {
    console.log('e: ', e);
  }
};

export default function App(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const { current: cookies } = useRef(new Cookies());

  useTranscend();

  useEffect(() => {
    const onMountLocation = window.location.pathname;

    const previousCookie = cookies.get('intial_landing_page');
    const newCookie = cookies.get('initial_landing_page');

    // set initial landing page cookie
    if (previousCookie) {
      // Use an existing (typo) cookie
      setCookie(cookies, 'initial_landing_page', previousCookie);
    } else if (!newCookie) {
      setCookie(cookies, 'initial_landing_page', onMountLocation);
    }

    checkOptimizelyForDecision(cookies);

    // set previous url
    setCookie(cookies, 'previous_url', onMountLocation);

    const handleRouteChangeStart = () => {
      setCookie(cookies, 'previous_url', window.location.pathname);
    };

    const handleRouteChangeComplete = () => {
      // closes our nav for us on route change...
      reinitNavDropdowns();
      checkOptimizelyForDecision(cookies);
    };

    document.addEventListener('gtmScrollTriggerFired', function (e) {
      const scrollCookieName = `opt_scroll_${e.detail.scrollDepth}`;
      const gtmScrollEventRegistered = cookies.get(scrollCookieName);

      if (!gtmScrollEventRegistered) {
        setCookie(cookies, scrollCookieName, true);

        const optimizelyExperimentNames = getOptimizelyExperimentNames();

        optimizelyExperimentNames.forEach((experimentName) => {
          const variation = getOptimizelyExperimentVariation(experimentName);

          if (variation !== ExperimentDecisions.OutOfExperiment) {
            if (typeof window !== 'undefined') {
              fetch(
                `${window.location.origin}/api/www-vercel-optimizely-events?eventKeys=scroll_depth_${e.detail.scrollDepth}_reached`
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log(data);
                })
                .catch((error) => {
                  console.error('Fetch error: ', error);
                });
            }
          }
        });
      }
    });

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  let AppContent = <Component {...pageProps} />;

  // only development builds get the session provider
  if (isDevelopment) {
    AppContent = (
      <SessionProvider session={pageProps.session}>
        {AppContent}
      </SessionProvider>
    );
  }

  return <ErrorBoundary name="global">{AppContent}</ErrorBoundary>;
}
